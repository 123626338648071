import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ContentDepthParagraphWithTitle({ title, paragraph }) {
  return (
    <Box sx={{ width: "90vw", maxWidth: "1000px", m: "0 auto" }}>
      <Typography
        sx={{
          fontWeight: 700,
          lineHeight: "normal",
          width: "90vw",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
        variant="h6"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          lineHeight: "normal",
          width: "90vw",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
        variant="h6"
      >
        {paragraph}
      </Typography>
    </Box>
  );
}

export default ContentDepthParagraphWithTitle;
