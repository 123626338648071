import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const fontStyle = {
  fontFamily: "Inter",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#424245",
  width: "100%",
};

const imageStyle = {
  width: "40vw",
  maxWidth: "200px",
  //width: '31vw'
};

function CoreValuesBlock({ img, text, imgSide }) {
  return (
    <>
      <Stack
        direction={{
          xs: "column-reverse",
          md: imgSide === "right" ? "row" : "row-reverse",
        }}
        sx={{
          width: "100%",
          mb: { xl: 3 },
          textAlign: {
            xs: "center",
            md: imgSide === "right" ? "right" : "left",
          },
          mb: { xs: 7, md: 0 },
        }}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography variant="h4" sx={{ ...fontStyle }}>
          {text}
        </Typography>
        <img src={img} style={{ ...imageStyle }} />
      </Stack>
    </>
  );
}

export default CoreValuesBlock;
