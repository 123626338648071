import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import { Divider } from "@mui/material";

function SimpleHero({ title, subtitle, backgroundColor }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;

  return (
    <Box sx={{ width: "100vw", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 4,
          top: 0,
          left: 0,
          position: "absolute",
        }}
      />
      <div
        className={`small-header ${screenSize}`}
        style={{ backgroundColor: backgroundColor }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontFamily: "Inter",
            zIndex: 5,
            fontWeight: 700,
            width: "100vw",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontFamily: "Inter",
                zIndex: 5,
                fontWeight: 400,
                mt: { xs: 1 },
                width: "100vw",
                textAlign: "center",
                // border: '1px solid red',
                width: "100%",
              }}
            >
              {subtitle}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}

export default SimpleHero;
