import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import StyledButton from "../components/styledButton/styledButton";
import centeredStyle from "../styles/centeredStyle";
import ContactModal from "../components/contactModal/contactModal";

const fontStyle = {
  fontWeight: 700,
  lineHeight: "normal",
};

function CTA({ bgcolor, buttonText, text }) {
  const [open, setOpen] = useState(false);
  const array = text ? text.map((text) => text.line) : [];
  console.log(text);

  return (
    <>
      <ContactModal open={open} setOpen={setOpen} />
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          py: { xs: "20vh", md: "25vh", lg: "30vh", xl: "20vh" },
          bgcolor: bgcolor,
        }}
      >
        <Box sx={{ ...centeredStyle, textAlign: "center", width: "90vw" }}>
          <>
            <Typography variant="h3" sx={fontStyle} component="div">
              {array[0]?.split("*")[0]}{" "}
              <b className="right">{array[0]?.split("*")[1]}</b>{" "}
              {array[0]?.split("*")[2]}
            </Typography>
            <Typography
              variant="h3"
              component="div"
              sx={{ lineHeight: "normal", fontWeight: 500 }}
            >
              {array[1]?.split("*")[0]}{" "}
              <b className="right">{array[1]?.split("*")[1]}</b>{" "}
              {array[1]?.split("*")[2]}
            </Typography>
            {array[2] && (
              <Typography
                variant="h3"
                component="div"
                sx={{ lineHeight: "normal", fontWeight: 500 }}
              >
                {array[2]?.split("*")[0]}{" "}
                <b className="right">{array[2]?.split("*")[1]}</b>{" "}
                {array[2]?.split("*")[2]}
              </Typography>
            )}
          </>

          <StyledButton
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              borderRadius: "30px",
              width: "200px",
              mt: { xs: 4, lg: 6 },
            }}
            onClick={() => setOpen(true)}
          >
            {buttonText}
          </StyledButton>
        </Box>
      </Box>
    </>
  );
}

export default CTA;
