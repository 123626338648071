import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledButton from "../components/styledButton/styledButton";
import centeredStyle from "../styles/centeredStyle";
import { useContext } from "react";
// import { ScreenSizeContext } from "../../../../src/App.js";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import ContactModal from "../components/contactModal/contactModal";

function SecondaryContent({ title, body, img, overlayOpacity }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;
  const [open, setOpen] = useState(false);

  return (
    <>
      <ContactModal open={open} setOpen={setOpen} />
      <div
        id={`solutions_${screenSize}`}
        style={{ backgroundImage: `url(${img})` }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            bgcolor: `rgba(0,0,0,${overlayOpacity})`,
            height: "100%",
            zIndex: -3,
          }}
        />
        <Box
          sx={{
            ...centeredStyle,
            width: "90%",
            maxWidth: "800px",
            zIndex: -2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontFamily: "Inter",
              fontWeight: 600,
              lineHeight: 1.25,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              fontFamily: "Inter",
              my: 5,
              lineHeight: "130%",
            }}
          >
            {body}
          </Typography>
          <StyledButton
            sx={{
              bgcolor: "white",
              fontFamily: "Inter",
              borderRadius: "30px",
              px: 5,
              color: "black",
              fontWeight: 500,
              fontSize: `calc(14px + 0.390625vw)`,
            }}
            onClick={() => setOpen(true)}
            variant="contained"
          >
            let's get started
          </StyledButton>
        </Box>
      </div>
    </>
  );
}

export default SecondaryContent;
