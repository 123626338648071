import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";

function VideoFeature({
  videoUrl,
  titleLine1,
  titleLine2,
  descriptionLine1,
  descriptionLine2,
  link,
}) {
  const screenSizeContext = React.useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;

  return (
    <Box sx={{ py: { xs: "5vh", md: "10vh", lg: "15vh", xl: "10vh" } }}>
      <Grid
        container
        width={{ xs: "90vw", lg: "80vw", xl: "60vw" }}
        ml={{ xs: "5vw", lg: "10vw", xl: "20vw" }}
      >
        <Grid item sm={2} display={{ xs: "none", sm: "block", md: "none" }} />
        <Grid
          item
          xs={12}
          md={6}
          pr={{ xs: 0, md: 3, xl: 5 }}
          order={{ xs: 2, md: 1 }}
        >
          <iframe
            src={videoUrl}
            style={{
              minHeight: "100%",
              minWidth: "100%",
              objectFit: "cover",
              height:
                screenSize === "small" || screenSize === "medium"
                  ? "300px"
                  : "100%",
            }}
            frameborder="0"
          ></iframe>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          pl={{ xs: 0, lg: 3, xl: 5 }}
          my="auto"
          order={{ xs: 1, md: 2 }}
          pb={{ xs: 5, md: 0 }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              textAlign: { xs: "center", md: "left" },
              fontFamily: "Inter",
            }}
          >
            {titleLine1}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              fontFamily: "Inter",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {titleLine2}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              maxWidth: "500px",
              pt: { xs: 2, lg: 3, xl: 3 },
              m: { xs: "0 auto", md: 0 },
              textAlign: {
                xs: "center",
                md: "left",
              },
              color: "rgba(67, 67, 68, 1)",
              fontFamily: "Inter",
              fontWeight: 500,
            }}
            variant="body1"
          >
            {descriptionLine1}
          </Typography>
          {descriptionLine2 && (
            <Typography
              sx={{
                width: "100%",
                maxWidth: "500px",
                pt: { xs: 2, lg: 3, xl: 3 },
                m: { xs: "0 auto", md: 0 },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
                color: "rgba(67, 67, 68, 1)",
                fontFamily: "Inter",
                fontWeight: 500,
              }}
              variant="body1"
            >
              {descriptionLine2}
            </Typography>
          )}
          {link && (
            <Link
              href={link.href}
              target="_blank"
              rel="noreferrer"
              // sx={{ color: '#86868B', lineHeight: 'normal' }}
              // color='#86868B'
              sx={{
                textDecoration: "underline 2px solid rgba(67,67,67,1)",
                ":hover": {
                  textDecoration: "underline 2px solid rgba(67,67,67,1)",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  pt: { xs: 2, lg: 3, xl: 3 },
                  m: { xs: "0 auto", md: 0 },
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                  fontFamily: "Inter",
                  color: "rgba(67, 67, 68, 1)",
                  fontWeight: 500,
                }}
                variant="body1"
              >
                {link.text}
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default VideoFeature;
