import React, { useContext, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import ContactModal from "../components/contactModal/contactModal";
import StyledButton from "../components/styledButton/styledButton";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const centeredStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 5,
  textAlign: "center",
};

function Hero({
  videoURL,
  placeholder,
  title,
  subtitle,
  description,
  tagline,
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;

  const handleScroll = () => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ContactModal open={open} setOpen={setOpen} />

      <Box
        sx={{
          position: "relative",
          height: screenSize === "small" ? "70vh" : "100vh",
        }}
      >
        <Box
          sx={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100%",
            bgcolor: "rgba(0,0,0,.4)",
            position: "absolute",
            zIndex: 4,
          }}
        />

        {screenSize !== "small" && (
          <>
            <img
              alt=""
              src={placeholder}
              style={{
                width: "100vw",
                height: "100vh",
                objectFit: "cover",
                right: 0,
                top: 0,
                zIndex: -2,
                position: "relative",
              }}
            />
            <video autoPlay loop muted preload="none" poster={placeholder}>
              <source src={videoURL} />
            </video>
          </>
        )}

        {screenSize === "small" && (
          <img
            src={placeholder}
            style={{
              width: "100vw",
              height: "100%",
              objectFit: "cover",
              right: 0,
              top: 0,
              zIndex: -1,
              position: "absolute",
            }}
          />
        )}

        <Box
          sx={{
            color: "white",
            ...centeredStyle,
            width: "90vw",
            top: "calc(50% + 36px)",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              width: "70%",
              m: "0 auto",
              pt: { xs: 4, lg: 3, xl: 4 },
              textAlign: "center",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "normal",
            }}
            variant="h5"
          >
            {subtitle}
          </Typography>
          {screenSize !== "small" && (
            <Typography
              sx={{
                width: "100%",
                maxWidth: {
                  xs: "675px",
                  lg: "550px",
                  xl: "675px",
                },
                pt: { xs: 2, md: 3, lg: 3, xl: 4 },
                textAlign: "center",
                m: "0 auto",
                fontFamily: "Inter",
                fontWeight: 400,
                lineHeight: "normal",
              }}
              variant="body1"
            >
              {description}
            </Typography>
          )}
          <Typography
            sx={{
              width: "80%",
              maxWidth: "750px",

              pt: { xs: 10, md: 3, lg: 3, xl: 4 },
              textAlign: "center",
              m: "0 auto",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "normal",
            }}
            variant="body1"
          >
            {tagline}
          </Typography>
          <Stack
            direction="row"
            spacing={5}
            sx={{
              justifyContent: "center",
              mt: { xs: 4, sm: 7 },
            }}
          >
            <StyledButton
              sx={{
                bgcolor: "white",
                fontFamily: "Inter",
                borderRadius: "30px",
                width: "140px",
                color: "black",
                fontWeight: 500,
                height: "37px",
                border: "2px solid white",
              }}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              get started <NavigateNext sx={{ fontSize: "16px" }} />
            </StyledButton>

            {screenSize !== "small" && (
              <StyledButton
                sx={{
                  bgcolor: "black",
                  fontFamily: "Inter",
                  borderRadius: "30px",
                  width: "140px",
                  color: "white",
                  fontWeight: 500,
                  border: "2px solid white",
                  height: "37px",
                }}
                variant="contained"
                onClick={handleScroll}
              >
                Learn More <ExpandMoreIcon sx={{ fontSize: "16px" }} />
              </StyledButton>
            )}
          </Stack>
        </Box>
      </Box>
      <Box ref={ref} />
    </>
  );
}

export default Hero;
