import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import sima from "../assets/snow/simaTransparent.png";
import Stack from "@mui/material/Stack";
import csp from "../assets/snow/cspTransparent.png";

function SnowHero({ title, subtitle }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;

  return (
    <Box sx={{ width: "100vw", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 4,
          top: 0,
          left: 0,
          position: "absolute",
          py: 5,
        }}
      />
      <div
        className={`small-header ${screenSize}`}
        style={{ backgroundColor: "#86868B" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant={screenSize === "small" ? "h4" : "h3"}
          sx={{
            color: "white",
            fontFamily: "Inter",
            zIndex: 5,
            fontWeight: 700,
            width: "100vw",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={screenSize !== "small" && "h6"}
          sx={{
            color: "white",
            fontFamily: "Inter",
            zIndex: 5,
            fontWeight: 600,
            mt: { xs: 1 },
            width: "100vw",
            textAlign: "center",
            // border: '1px solid red',
            width: "100%",
          }}
        >
          {subtitle}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <div>
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "normal",
                mr: 3,
              }}
            >
              Backed +
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "normal",
                mr: 3,
              }}
            >
              Certified:
            </Typography>
          </div>
          <img src={sima} alt="SIMA Certified" />
          <img src={csp} alt="CSP Certified" />
        </Stack>
      </Box>
    </Box>
  );
}

export default SnowHero;
