import React from "react";
import { Box, Typography, Stack } from "@mui/material";

function SponsorshipContentThin({ images }) {
  const array = images || [];
  return (
    <Box sx={{ bgcolor: "#d9d9d9", py: 1 }}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 600,
          lineHeight: "30px",
        }}
        variant="subtitle2"
      >
        In partnership with:
      </Typography>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        {array.map((image) => (
          <img src={image.img} alt="" />
        ))}
      </Stack>
    </Box>
  );
}

export default SponsorshipContentThin;
