import NavigateNext from "@mui/icons-material/NavigateNext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";

function Sliver({ href, text }) {
  return (
    <>
      <Toolbar
        elevation={0}
        variant="dense"
        sx={{
          width: "100vw",
          justifyContent: "center",
          display: "flex",
          bgcolor: "white",
          zIndex: 5,
          height: "36px",
          maxHeight: "36px",
          minHeight: "36px",
        }}
      >
        <Typography
          sx={{
            color: "#1D1D1F",
            fontWeight: 500,
          }}
          variant='subtitle1'
        >
          {text}
        </Typography>
        <Typography
          sx={{
            color: "#1D1D1F",
            ml: 1,
            // color: "#2997FF",
            fontWeight: 500,
            textDecoration: "none",
          }}
          variant='subtitle1'
          component="a"
          href={href}
        >
          Learn more <NavigateNext sx={{ fontSize: "15px" }} />
        </Typography>
      </Toolbar>
    </>
  );
}

export default Sliver;
