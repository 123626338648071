import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const VideoComp = ({ src, title }) => (
  <iframe
    src={src}
    title={title}
    frameBorder="0"
    allowFullScreen
    style={{
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      height: "300px",
      // screenSize === "small" || screenSize === "medium" ? "300px" : "500px",
    }}
  ></iframe>
);

function DoubleVideoFeature({ video1, video2, text1, text2 }) {
  const array1 = text1 || [];
  const array2 = text2 || [];
  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        width={{ xs: "90vw", lg: "80vw", xl: "54vw" }}
        ml={{ xs: "5vw", lg: "10vw", xl: "23vw" }}
        py={{ xs: "5vh", lg: "10vh", xl: "8vh" }}
        spacing={6}
        alignItems={"center"}
      >
        <Box sx={{ width: { xs: "100%", md: "46%" } }}>
          {array1.map((text) => (
            <Typography variant="body1" sx={{ lineHeight: "normal" }}>
              {text.paragraph}
            </Typography>
          ))}
        </Box>
        <Box sx={{ width: { xs: "100%", md: "53%" } }}>
          <VideoComp src={video1} title="" />
        </Box>
      </Stack>
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        width={{ xs: "90vw", lg: "80vw", xl: "54vw" }}
        ml={{ xs: "5vw", lg: "10vw", xl: "23vw" }}
        pb={{ xs: "5vh", lg: "10vh", xl: "8vh" }}
        spacing={6}
        alignItems={"center"}
      >
        <Box sx={{ width: { xs: "100%", md: "55%" } }}>
          <VideoComp src={video2} />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "46%" } }}>
          {array2.map((text) => (
            <Typography variant="body1" sx={{ lineHeight: "normal" }}>
              {text.paragraph}
            </Typography>
          ))}
        </Box>
      </Stack>
    </>
  );
}

export default DoubleVideoFeature;
