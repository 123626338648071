import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import Button from "@mui/material/Button";

function SubNavbar({ links, backgroundColor, textColor }) {
  const array = links || [];
  const style = {
    color: textColor,
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: 300,
    mx: 2,
    fontFamily: "Inter",
  };

  const location = useLocation();
  const pathname = location.pathname;

  // console.log(pathname);

  return (
    <Toolbar
      elevation={0}
      variant="dense"
      sx={{
        width: "100vw",
        justifyContent: "center",
        display: "flex",
        bgcolor: backgroundColor,
        zIndex: 9,
        height: "36px",
        mt: "6px",
      }}
    >
      {array.map((link) => (
        <Button
          sx={{
            ...style,
            fontWeight: pathname === `${link.href}/` ? 700 : 300,
          }}
          key={link.href}
          onClick={() => navigate(link.href)}
        >
          {link.text}
        </Button>
      ))}
    </Toolbar>
  );
}

export default SubNavbar;
