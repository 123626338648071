const modalStyle = {   
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '90%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    p: 5,
    maxWidth: '600px'
};

export default modalStyle;