import React from "react";
import Typography from "@mui/material/Typography";

function ContentDepthParagraph({ paragraph }) {
  return (
    <Typography
      sx={{
        fontWeight: 500,
        lineHeight: "normal",
        width: "90vw",
        maxWidth: "1000px",
        m: "0 auto",
      }}
      variant="h6"
    >
      {paragraph}
    </Typography>
  );
}

export default ContentDepthParagraph;
