import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StyledButton from "../components/styledButton/styledButton";
import { navigate } from "gatsby";
import NavigateNext from "@mui/icons-material/NavigateNext";

function FacilityComp({ img, title, description, href }) {
  return (
    <Box
      onClick={() => navigate(href)}
      sx={{
        border: "1px solid black",
        boxShadow: 0,
        borderRadius: 4,
        "&:hover": {
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        },
        bgcolor: "transparent",
        p: 3,
        width: "100%",
      }}
    >
      <img src={img} alt="" style={{ width: "100%" }} />

      <Typography
        sx={{
          fontWeight: 700,
          mt: 4,
          mb: 2,
          fontFamily: "Inter",
        }}
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          mb:
            title === "K-12 Schools" || title === "Colleges + Universities"
              ? 6
              : 3,
          color: "rgba(67, 67, 68, 1)",
        }}
        variant="body2"
      >
        {description}
      </Typography>

      <StyledButton
        sx={{
          color: "black",
          fontWeight: 500,
          pl: 0,
        }}
        onClick={() => navigate(href)}
      >
        Learn More{" "}
        <NavigateNext fontSize="small" sx={{ verticalAlign: "middle" }} />
      </StyledButton>
    </Box>
  );
}

export default FacilityComp;
