import React from "react";
import Typography from "@mui/material/Typography";

function ContentDepthLink({ text, href }) {
  return (
    <Typography
      sx={{
        fontWeight: 700,
        lineHeight: "normal",
        width: "90vw",
        maxWidth: "1000px",
        margin: "0 auto",
        textDecoration: "none",
        color: "black",
      }}
      variant="h6"
      component="a"
      href={href}
    >
      {text}
    </Typography>
  );
}

export default ContentDepthLink;
