import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const VideoComp = ({ src, title }) => (
  <iframe
    src={src}
    title={title}
    frameborder="0"
    allowFullScreen
    style={{
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      height: "300px",
      // screenSize === "small" || screenSize === "medium" ? "300px" : "500px",
    }}
  ></iframe>
);

const stackProps = {
  width: { xs: "90vw", lg: "80vw", xl: "54vw" },
  ml: { xs: "5vw", lg: "10vw", xl: "23vw" },
  spacing: 6,
  alignItems: "center",
};

function VideoDoubleFeature({ section1, section2 }) {
  const body1 = section1?.body || [];
  const body2 = section2?.body || [];

  return (
    <>
      <Stack
        {...stackProps}
        direction={{ xs: "column", md: "row" }}
        py={{ xs: "5vh", lg: "10vh", xl: "8vh" }}
      >
        <Box sx={{ width: { xs: "100%", md: "46%" } }}>
          <Stack spacing={3}>
            {body1.map((p) => (
              <Typography variant="body1" sx={{ lineHeight: "normal" }}>
                {p.paragraph}
              </Typography>
            ))}
          </Stack>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "53%" } }}>
          <VideoComp src={section1?.video} title={section1?.title} />
        </Box>
      </Stack>
      <Stack
        {...stackProps}
        direction={{ xs: "column-reverse", md: "row" }}
        pb={{ xs: "5vh", lg: "10vh", xl: "8vh" }}
      >
        <Box sx={{ width: { xs: "100%", md: "55%" } }}>
          <VideoComp src={section2?.video} title={section2?.title} />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "46%" } }}>
          <Stack spacing={3}>
            {body2.map((p) => (
              <Typography variant="body1" sx={{ lineHeight: "normal" }}>
                {p.paragraph}
              </Typography>
            ))}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

export default VideoDoubleFeature;
