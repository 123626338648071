import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const titleStyle = {
  fontFamily: "Inter",
  fontWeight: 600,
  mb: 2,
};

const ContactColumn = ({ title, phone, email, tel }) => (
  <Grid
    item
    xs={12}
    md={4}
    lg={4}
    my="auto"
    sx={{
      textAlign: "center",
      mt: { xs: title !== "Sales" ? 5 : 0, md: 0 },
    }}
  >
    <Typography sx={titleStyle} variant="h3">
      {title}
    </Typography>
    <Link href={`tel:+${tel}`} underline="none" sx={{ color: "black" }}>
      <Typography sx={{ fontFamily: "Inter", fontWeight: 400 }} variant="body1">
        {phone}
      </Typography>
    </Link>
    <Link href={`mailto:${email}`} sx={{ color: "black" }} underline="none">
      <Typography sx={{ fontFamily: "Inter", fontWeight: 400 }} variant="body1">
        {email}
      </Typography>
    </Link>
  </Grid>
);

function CorporateContact() {
  return (
    <Box
      sx={{
        py: { xs: "7vh", lg: "15vh", xl: "8vh" },
        width: { xs: "90vw", lg: "70vw", xl: "70vw" },
        ml: { xs: "5vw", lg: "15vw" },
      }}
    >
      <Grid container>
        <ContactColumn
          title="Sales"
          phone="855-827-2532"
          tel="8558272532"
          email="sales@transblue.org"
        />
        <ContactColumn
          title="Operations"
          phone="877-467-1131"
          tel="8774671131"
          email="service@transblue.org"
        />
        <ContactColumn
          title="General Inquiries"
          phone="844-482-2583"
          tel="8444822583"
          email="inquiries@transblue.org"
        />
      </Grid>
    </Box>
  );
}

export default CorporateContact;
