import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import NavigateNext from "@mui/icons-material/NavigateNext";

const sectionHeight = {
  py: { xs: "10vh", md: "12vh", lg: "15vh", xl: "10vh" },
};

const bodyStyle = {
  color: "#86868B",
  lineHeight: "normal",
  fontFamily: "Inter",
  fontWeight: 500,
  maxWidth: "80vw",
  m: "0 auto",
};

const LearnMore = ({ link }) => {
  return (
    <Typography
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      sx={{
        color: "#007AFF",
        mt: 2,
        fontFamily: "Inter",
        fontWeight: 500,
        textDecoration: "none",
      }}
      variant="body1"
    >
      Learn more <NavigateNext sx={{ fontSize: "18px" }} />
    </Typography>
  );
};

const LeftText = ({ array, link, img, screenSize, bgcolor }) => {
  return (
    <Box
      sx={{
        width: "90vw",
        // border: '1px solid red',
        maxWidth: { lg: "70vw", xl: "60vw" },
        margin: "0 auto",
        textAlign: { xs: "center", md: "left" },
        ...sectionHeight,
        bgcolor: bgcolor,
      }}
    >
      <Grid container spacing={{ xs: 3, lg: 5 }}>
        <Grid item xs={12} md={6} my="auto">
          {array.map((sentence) => (
            <Typography variant="body1" sx={{ ...bodyStyle, mb: 2 }}>
              {sentence.paragraph}
            </Typography>
          ))}
          {link && <LearnMore link={link} />}
        </Grid>
        <Grid item xs={12} md={6} my="auto">
          <Box
            sx={{
              width: { xs: "75%", lg: "100%" },
              textAlign: "center",
              m: "0 auto",
            }}
          >
            <img
              src={img}
              style={{ width: screenSize === "small" ? "100%" : "350px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const RightText = ({ array, link, img, screenSize, bgcolor }) => {
  return (
    <Box sx={{ bgcolor: bgcolor, width: "100vw" }}>
      <Box
        sx={{
          width: "90vw",
          // border: '1px solid red',
          maxWidth: { lg: "70vw", xl: "60vw" },
          margin: "0 auto",
          textAlign: { xs: "center", md: "left" },
          ...sectionHeight,
        }}
      >
        <Grid container spacing={{ xs: 3, lg: 10 }}>
          <Grid item xs={12} md={6} my="auto">
            <Box
              sx={{
                width: {
                  xs: "75%",
                  lg: "100%",
                },
                textAlign: "center",
                m: "0 auto",
              }}
            >
              <img
                src={img}
                style={{ width: screenSize === "small" ? "100%" : "350px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} my="auto">
            {array.map((sentence, index) => (
              <Typography
                variant="body1"
                sx={{ ...bodyStyle, mb: 2 }}
                key={index}
              >
                {sentence.paragraph}
              </Typography>
            ))}
            {link && <LearnMore link={link} />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function BrandHighlightSection({ logoSide, text, link, img, bgcolor }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;
  const array = text || [];

  return logoSide === "left" ? (
    <RightText
      text={text}
      array={array}
      link={link}
      img={img}
      screenSize={screenSize}
      bgcolor={bgcolor}
    />
  ) : (
    <LeftText
      text={text}
      array={array}
      link={link}
      img={img}
      screenSize={screenSize}
      bgcolor={bgcolor}
    />
  );
}

export default BrandHighlightSection;
