import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import centeredStyle from "../styles/centeredStyle";
import StyledButton from "../components/styledButton/styledButton";
import reviews from "../assets/landing/Featured Review Sites.webp";
import { styled } from "@mui/material/styles";

const StyledHeaderBody = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: "Inter",
  padding: "24px 0 40px 0",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "500px",
  },
  margin: "0 auto",
  lineHeight: "normal",
}));

const headerStyle = {
  fontFamily: "Inter",
  fontWeight: 700,
  lineHeight: "90%",
};

function HeroHome({ video, placeholder, textLine1, textLine2, body }) {
  const ref = useRef();

  const handleScroll = () => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop,
    });
  };

  return (
    <>
      <Box className="overlay" />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src={placeholder}
          style={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            right: 0,
            top: 0,
            zIndex: -2,
            position: "relative",
          }}
        />
        <video autoPlay loop muted preload="none" poster={placeholder}>
          <source src={video} />
        </video>
      </Box>
      <Box
        sx={{
          color: "white",
          ...centeredStyle,
          width: "90vw",
          top: "calc(50% + 36px)",
        }}
      >
        <Typography variant="h1" sx={headerStyle}>
          {textLine1}
        </Typography>
        <Typography variant="h1" sx={headerStyle}>
          {textLine2}
        </Typography>
        <StyledHeaderBody variant="h5">{body}</StyledHeaderBody>
        <StyledButton
          sx={{
            border: "1px solid white",
            bgcolor: "#007AFF",
            fontSize: `calc(16px + 0.390625vw)`,
          }}
          variant="contained"
          onClick={handleScroll}
        >
          Let's get started <ExpandMore />
        </StyledButton>

        <Box sx={{ mt: 5 }}>
          <img
            src={reviews}
            alt=""
            style={{
              margin: "0 auto",
              maxWidth: "90vw",
              width: "250px",
            }}
          />
        </Box>
      </Box>
      <div ref={ref} />
    </>
  );
}

export default HeroHome;
