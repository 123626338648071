import React from "react";
import { createTheme } from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

function ThemeContext({ children }) {
  let fontTheme = createTheme();
  // fontTheme = responsiveFontSizes(fontTheme);

  // const breakpointOverrides = {
  //   xs: 0,
  //   sm: 600,
  //   md: 900,
  //   lg: 1200,
  //   xl: 1440,
  // };

  const interTheme = createTheme({
    root: {
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    menu: {
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      // a: {
      //   textDecoration: "none",
      // },
      // textDecoration: "none",
      h1: {
        fontSize: `calc(60px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 700,
        letterSpacing: "-1.5px",
        lineHeight: "116.70000553131104%",
      },
      h2: {
        fontSize: `calc(40px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "-0.5px",
        lineHeight: "120.00000476837158%",
      },
      h3: {
        fontSize: `calc(36px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0px",
        lineHeight: "116.70000553131104%",
      },
      h4: {
        fontSize: `calc(32px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0.25px",
        lineHeight: "123.50000143051147%",
      },
      h5: {
        fontSize: `calc(26px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 700,
        letterSpacing: "0px",
        lineHeight: "133.39999914169312%",
      },
      h6: {
        fontSize: `calc(22px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "160.0000023841858%",
      },
      body1: {
        fontSize: `calc(16px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "150%",
      },
      body2: {
        fontSize: `calc(14px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.17000000178813934px",
        lineHeight: "142.99999475479126%",
      },
      subtitle1: {
        fontSize: `calc(18px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "175%",
      },
      subtitle2: {
        fontSize: `calc(16px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.10000000149011612px",
        lineHeight: "157.00000524520874%",
      },
      overline: {
        fontSize: `calc(12px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "1px",
        lineHeight: "266.00000858306885%",
      },
      caption: {
        fontSize: `calc(12px + 0.390625vw)`,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 300,
        letterSpacing: "0.4000000059604645px",
        lineHeight: "165.9999966621399%",
      },
      "alert/title": {
        fontSize: 16,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "150%",
      },
      "alert/description": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "142.99999475479126%",
      },
      "avatar/initials": {
        fontSize: 20,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.14000000059604645px",
        lineHeight: "20px",
      },
      "badge/label": {
        fontSize: 12,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.14000000059604645px",
        lineHeight: "20px",
      },
      "bottomnavigation/activelabel": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.4000000059604645px",
        lineHeight: "165.9999966621399%",
      },
      "button/large": {
        fontSize: 15,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0.46000000834465027px",
        lineHeight: "26px",
      },
      "button/medium": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0.4000000059604645px",
        lineHeight: "24px",
      },
      "button/small": {
        fontSize: 13,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        letterSpacing: "0.46000000834465027px",
        lineHeight: "22px",
      },
      "chip/label": {
        fontSize: 13,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.1599999964237213px",
        lineHeight: "18px",
      },
      "datepicker/currentmonth": {
        fontSize: 16,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "150%",
      },
      "input/label": {
        fontSize: 12,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "12px",
      },
      "input/value": {
        fontSize: 16,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "24px",
      },
      "input/helper": {
        fontSize: 12,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.4000000059604645px",
        lineHeight: "165.9999966621399%",
      },
      "list/subheader": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.10000000149011612px",
        lineHeight: "48px",
      },
      "menu/itemdefault": {
        fontSize: 16,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "150%",
      },
      "menu/itemdense": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: "0.17000000178813934px",
        lineHeight: "24px",
      },
      "table/header": {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.17000000178813934px",
        lineHeight: "24px",
      },
      "tooltip/label": {
        fontSize: 10,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0px",
        lineHeight: "14px",
      },
      "datagrid/aggregationcolumnheaderlabel": {
        fontSize: 12,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "0.15000000596046448px",
        lineHeight: "12px",
      },
      "_library/heading": {
        fontSize: 64,
        fontFamily: "Inter, sans-serif",
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: "-1.5px",
        lineHeight: "116.70000553131104%",
      },
    },
    MuiButton: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: ["Inter", "sans-serif"].join(","),
        },
      },
    },
    MuiLink: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: ["Inter", "sans-serif"].join(","),
        },
      },
    },
  });

  return (
    <ThemeProvider theme={fontTheme}>
      <ThemeProvider theme={interTheme}>{children}</ThemeProvider>
    </ThemeProvider>
  );
}

export default ThemeContext;
