import React from "react";
import ThemeContext from "./src/contextWrappers/themeContext";
import ScreenSizeContextWrapper from "./src/contextWrappers/screenSizeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import PageFirstSeen from "./src/contextWrappers/pageFirstSeen";

import { builder, Builder } from "@builder.io/react";
import Facilities from "./src/newSrc2/components/facilities/facilities";
import Navbar from "./src/newSrc2/builderComponents/Navbar";
import Footer from "./src/newSrc2/components/footer/newFooter";
import Brands from "./src/newSrc2/components/brands/brands";
import Leadership from "./src/newSrc2/components/leadership/leadership";
import Awards from "./src/newSrc2/components/awards/awards";
import Review from "./src/newSrc2/builderComponents/CustomerReview";
import SubNavbar from "./src/newSrc2/builderComponents/SubNavbar";
import Sliver from "./src/newSrc2/builderComponents/Sliver";
import FacilityComp from "./src/newSrc2/builderComponents/Facility";
import ServiceCard from "./src/newSrc2/builderComponents/ServiceCard";
import Hero from "./src/newSrc2/builderComponents/Hero";
import VideoFeature from "./src/newSrc2/builderComponents/VideoFeature";
import CoreServices from "./src/newSrc2/builderComponents/CoreServices";
import CoreValuesBlock from "./src/newSrc2/builderComponents/CoreValuesBlock";
import BrandHighlightSection from "./src/newSrc2/builderComponents/BrandHighlight";
import CorporateContact from "./src/newSrc2/builderComponents/CorporateContact";
import DoubleVideoFeature from "./src/newSrc2/builderComponents/DoubleVideoFeature";
import EmbeddedForm from "./src/newSrc2/builderComponents/EmbeddedSubmissionForm";
import HeroContact from "./src/newSrc2/builderComponents/HeroContact";
import HeroHome from "./src/newSrc2/builderComponents/HeroHome";
import InformationContentBlock from "./src/newSrc2/builderComponents/InformationContentBlock";
import InformationContentThirds from "./src/newSrc2/builderComponents/InformationContentThirds";
import SimpleHero from "./src/newSrc2/builderComponents/SimpleHero";
import InternalLinkingFull from "./src/newSrc2/builderComponents/InternalLinkingFull";
import InternalLinkingThirds from "./src/newSrc2/builderComponents/InternalLinkingThirds";
import LandingContact from "./src/newSrc2/forms/landingContact";
import LeadershipTeamBlock from "./src/newSrc2/builderComponents/LeadershipBlock";
import SecondaryContent from "./src/newSrc2/builderComponents/SecondaryContent";
import SponsorshipContentThin from "./src/newSrc2/builderComponents/SponsorshipContentThin";
import VideoDoubleFeature from "./src/newSrc2/builderComponents/VideoDoubleFeature";
import VisualContentBlock from "./src/newSrc2/builderComponents/VisualContentBlock";
import CTA from "./src/newSrc2/builderComponents/CTA";
import MobileCTA from "./src/newSrc2/builderComponents/MobileCTA";

import SnowHero from "./src/newSrc2/builderComponents/SnowHero";
import ContentDepthParagraph from "./src/newSrc2/builderComponents/ContentDepthParagraph";
import ContentDepthList from "./src/newSrc2/builderComponents/ContentDepthList";
import LandscapingHero from "./src/newSrc2/builderComponents/LandscapingHero";
import ContentDepthParagraphWithTitle from "./src/newSrc2/builderComponents/ContentDepthParagraphWithTitle";
import ContentDepthLink from "./src/newSrc2/builderComponents/ContentDepthLink";

import("./src/styles/global.css");

builder.init("754435ce8a0f4319bd8d2636dfd065e1");

Builder.registerComponent(LandscapingHero, {
  name: "Hero landscaping variant",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "subtitle",
      type: "string",
    },
  ],
});

Builder.registerComponent(ContentDepthLink, {
  name: "Content Depth Link",
  inputs: [
    {
      name: "href",
      type: "string",
      friendlyName: "Link",
    },
    {
      name: "text",
      type: "string",
      friendlyName: "Text",
    },
  ],
});

Builder.registerComponent(ContentDepthList, {
  name: "Content Depth List",
  inputs: [
    { name: "title", type: "string" },
    {
      name: "list",
      type: "list",
      friendlyName: "Bullet Points",
      subFields: [{ name: "item", friendlyName: "text", type: "longText" }],
    },
  ],
});

Builder.registerComponent(ContentDepthParagraph, {
  name: "Content Depth Paragraph",
  inputs: [
    {
      name: "paragraph",
      type: "longText",
      defaultValue: "",
    },
  ],
});

Builder.registerComponent(ContentDepthParagraphWithTitle, {
  name: "Content Depth Paragraph With Title",
  inputs: [
    {
      name: "paragraph",
      type: "longText",
      defaultValue: "",
      friendlyName: "Paragraph",
    },
    {
      name: "title",
      type: "string",
      defaultValue: "",
      friendlyName: "Title",
    },
  ],
});

Builder.registerComponent(SnowHero, {
  name: "Hero snow variant",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "subtitle",
      type: "string",
    },
  ],
});

Builder.registerComponent(MobileCTA, {
  name: "Mobile CTA",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F4cf6f003ad36449eb1e1df4f1e25c5af",
  inputs: [
    {
      name: "bgcolor",
      type: "color",
      defaultValue: "#f5f5f7",
      friendlyName: "Background Color",
      helperText:
        "Background color of entire section, usually white or #f5f5f7",
    },
    {
      name: "text",
      type: "list",
      subFields: [
        {
          name: "line",
          type: "longText",
          helperText:
            'Use * to change text to blue. e.g. "This is a *blue* word"',
        },
      ],
      defaultValue: [
        { line: "Choose reliability and expertise;" },
        { line: "see how *Transblue* ends your" },
        { line: "nonstop frustrations." },
      ],
    },
    {
      name: "buttonText",
      type: "string",
      friendlyName: "Button Text",
      defaultValue: "Let's get started",
    },
  ],
});

Builder.registerComponent(CTA, {
  name: "CTA",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F4cf6f003ad36449eb1e1df4f1e25c5af",
  inputs: [
    {
      name: "bgcolor",
      type: "color",
      defaultValue: "#f5f5f7",
      friendlyName: "Background Color",
      helperText:
        "Background color of entire section, usually white or #f5f5f7",
    },
    {
      name: "text",
      type: "list",
      subFields: [
        {
          name: "line",
          type: "longText",
          helperText:
            'Use * to change text to blue. e.g. "This is a *blue* word"',
        },
      ],
      defaultValue: [
        { line: "Choose reliability and expertise; see how" },
        { line: "*Transblue* ends your nonstop frustrations" },
      ],
    },
    {
      name: "buttonText",
      type: "string",
      friendlyName: "Button Text",
      defaultValue: "Let's get started",
    },
  ],
});

Builder.registerComponent(VisualContentBlock, {
  name: "Visual Content Block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fd874c88a273b4bc1b9cf15155d063f24",
  inputs: [
    {
      name: "images",
      type: "list",
      subFields: [
        {
          name: "href",
          type: "string",
          friendlyName: "Link",
        },
        {
          name: "src",
          type: "file",
          friendlyName: "Image",
        },
        {
          name: "text",
          type: "string",
          friendlyName: "Text",
        },
      ],
    },
  ],
});

Builder.registerComponent(VideoDoubleFeature, {
  name: "Video Double Feature",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F1317c860da5c46a4bbbfca3952401fc2",
  inputs: [
    {
      name: "section1",
      type: "object",
      subFields: [
        {
          name: "video",
          type: "string",
          friendlyName: "Video URL",
        },
        {
          name: "title",
          type: "string",
          friendlyName: "Title",
          helperText: "Video title",
        },
        {
          name: "body",
          type: "list",
          subFields: [
            {
              name: "paragraph",
              type: "string",
            },
          ],
        },
      ],
    },
    {
      name: "section2",
      type: "object",
      subFields: [
        {
          name: "video",
          type: "string",
          friendlyName: "Video URL",
        },
        {
          name: "title",
          type: "string",
          friendlyName: "Title",
          helperText: "Video title",
        },
        {
          name: "body",
          type: "list",
          subFields: [
            {
              name: "paragraph",
              type: "string",
            },
          ],
        },
      ],
    },
  ],
});

Builder.registerComponent(SponsorshipContentThin, {
  name: "Sponsorship content thin block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F16ba76ec3c9c48cd8329d38363effce1",
  inputs: [
    {
      name: "images",
      type: "list",
      subFields: [
        {
          name: "img",
          type: "file",
          friendlyName: "image",
        },
      ],
    },
  ],
});

Builder.registerComponent(SecondaryContent, {
  name: "Secondary content block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fbb6e470ce66f46c1869e196ff9d70ea6",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "body",
      type: "longText",
    },
    {
      name: "img",
      friendlyName: "Background Image",
      type: "file",
    },
    {
      name: "overlayOpacity",
      type: "enum",
      friendlyName: "Overlay Opacity",
      enum: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      defaultValue: 0.5,
    },
  ],
});

Builder.registerComponent(LeadershipTeamBlock, {
  name: "Leadership team block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fefde73be53744d91b2ee22a9adc014cf",
  inputs: [
    {
      name: "name",
      type: "string",
    },
    {
      name: "title",
      type: "string",
    },

    {
      name: "body",
      type: "longText",
    },
    {
      name: "image",
      type: "file",
    },
    {
      name: "displayLink",
      type: "boolean",
      helperText: "Whether to display 'Connect with ...' link",
    },
    {
      name: "link",
      type: "string",
    },
    {
      name: "textSide",
      type: "enum",
      enum: ["left", "right"],
    },
  ],
});

Builder.registerComponent(LandingContact, {
  name: "1-2-3 form submission",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fc7a5e5fa0cd545cfbadf370dade99e6e",
  inputs: [],
});

Builder.registerComponent(InternalLinkingThirds, {
  name: "Internal Linking block, Thirds",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F99e449d315a641c88779f6450d96e330",
  inputs: [
    {
      name: "sections",
      type: "list",
      defaultValue: [],
      subFields: [
        {
          name: "img",
          friendlyName: "Image",
          type: "file",
        },
        {
          name: "title",
          type: "string",
        },
        {
          name: "href",
          type: "string",
          helperText: "e.g. /snow",
        },
      ],
    },
  ],
});

Builder.registerComponent(InternalLinkingFull, {
  name: "Internal Linking block, Full",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fbd7225dcb8e043e3a3e555a5354ae1e7",
  inputs: [
    {
      name: "text",
      type: "string",
    },
    {
      name: "img",
      type: "file",
      friendlyName: "Background Image",
    },
    {
      name: "href",
      type: "string",
      helperText: "e.g. /snow-and-ice-removal",
    },
  ],
});

Builder.registerComponent(SimpleHero, {
  name: "Hero section, simple",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fca9b3813d6d344df92a472fa6671ba53",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "subtitle",
      type: "string",
    },
    {
      name: "backgroundColor",
      type: "color",
    },
  ],
});

Builder.registerComponent(InformationContentThirds, {
  name: "Informational content thirds",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F276685bc734443b594b7da9b7b861b26",
  inputs: [
    {
      name: "sections",
      type: "list",
      defaultValue: [],
      subFields: [
        {
          name: "title",
          type: "text",
        },
        {
          name: "icon",
          type: "file",
        },
        {
          name: "text",
          type: "longText",
        },
      ],
    },
  ],
});

Builder.registerComponent(InformationContentBlock, {
  name: "Informational content block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fac52070eb2c341e9a6b13d3ea4cf8711",
  inputs: [
    {
      name: "sectionTitle",
      type: "string",
      friendlyName: "Section Title",
    },
    {
      name: "sectionBody",
      type: "longText",
      friendlyName: "Section Body",
    },
    {
      name: "link",
      type: "string",
      friendlyName: "Learn More Link",
    },
  ],
});

Builder.registerComponent(HeroHome, {
  name: "Hero Section (home page)",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F2c95c490461045d682c93b74d6569436",
  inputs: [
    {
      name: "video",
      type: "file",
    },
    {
      name: "placeholder",
      type: "file",
    },
    {
      name: "textLine1",
      type: "string",
      friendlyName: "Text Line 1",
    },
    {
      name: "textLine2",
      type: "string",
      friendlyName: "Text Line 2",
    },
    {
      name: "body",
      type: "longText",
      friendlyName: "Body",
    },
  ],
});

Builder.registerComponent(HeroContact, {
  name: "Hero Section (contact variant)",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F63fedeb312044095a5d7be260177ea18",
  inputs: [
    {
      name: "video",
      type: "string",
    },
    {
      name: "placeholder",
      type: "file",
    },
    {
      name: "headerText",
      type: "string",
      friendlyName: "Header Text",
    },
    {
      name: "headerTextLine2",
      type: "string",
      friendlyName: "Header Text Line 2",
    },
  ],
});

Builder.registerComponent(EmbeddedForm, {
  name: "General embedded submission form",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F62f52a8536754bf7acb572674a99e956",
  inputs: [],
});

Builder.registerComponent(DoubleVideoFeature, {
  name: "Double Video Feature",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F1317c860da5c46a4bbbfca3952401fc2",
  inputs: [
    {
      name: "video1",
      friendlyName: "Video 1",
      type: "string",
    },
    {
      name: "video2",
      friendlyName: "Video 2",
      type: "string",
    },
    {
      name: "text1",
      type: "list",
      subFields: [
        {
          name: "paragraph",
          type: "string",
        },
      ],
    },
    {
      name: "text2",
      type: "list",
      subFields: [
        {
          name: "paragraph",
          type: "string",
        },
      ],
    },
  ],
});

Builder.registerComponent(CorporateContact, {
  name: "Corporate Contact Information",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F5fb861b74b554645b0e06d0f681b927e",
  inputs: [],
});

Builder.registerComponent(BrandHighlightSection, {
  name: "Brand Highlight Section",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F3bd5d1e4cf354eafbfb8d67a81342b50",
  inputs: [
    {
      name: "bgcolor",
      type: "color",
      defaultValue: "#F5F5F7",
      friendlyName: "Background Color",
    },
    {
      name: "text",
      type: "list",
      subFields: [
        {
          name: "paragraph",
          type: "string",
          friendlyName: "Paragraph",
        },
      ],
      friendlyName: "Paragraphs",
    },
    {
      name: "link",
      type: "string",
      friendlyName: "Link",
    },
    {
      name: "img",
      type: "file",
      friendlyName: "Image",
    },
    {
      name: "logoSide",
      friendlyName: "Logo Side",
      type: "enum",
      enum: ["left", "right"],
    },
  ],
});

Builder.registerComponent(Facilities, {
  name: "Secondary content highlights, cards",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F6ad732ac81454674955d4c2956b30923",
  inputs: [
    {
      name: "displayTitle",
      type: "boolean",
      defaultValue: true,
      friendlyName: "Display Title",
    },
  ],
});

Builder.registerComponent(Navbar, {
  name: "Navbar",
  inputs: [{ name: "page", type: "string" }],
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F255858490ce74b2fb2e754531a7e2d94",
});

Builder.registerComponent(Footer, {
  name: "Footer",
  inputs: [],
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F0adfe2ded0ed48c8853a6369f5708bc8",
});

Builder.registerComponent(Brands, {
  name: "Top Clients Section",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F4c638b03ca5b415b9857bc9cd6fdc9ff",
  inputs: [
    {
      name: "bgcolor",
      type: "color",
      defaultValue: "white",
      friendlyName: "Background Color",
    },
  ],
});

Builder.registerComponent(Leadership, {
  name: "Leadership Section",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F792d10df47694675b3356caa066cf4fa",
  inputs: [
    {
      name: "bgcolor",
      type: "color",
      defaultValue: "white",
      friendlyName: "Background Color",
    },
    {
      name: "displayLinks",
      type: "boolean",
      defaultValue: true,
    },
  ],
});

Builder.registerComponent(Awards, {
  name: "Awards",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F8eeb4302999f425eb8cfd01aeebb9be5",
  inputs: [
    {
      name: "page",
      type: "string",
      defaultValue: "High Dusting",
    },
  ],
});

Builder.registerComponent(Review, {
  name: "Customer review block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F6da1b016702343ae8b0adf0c45989e54",
  inputs: [
    {
      name: "img",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      friendlyName: "Image",
    },
    {
      name: "reviewLine1",
      type: "string",
      friendlyName: "Review Line 1",
    },
    {
      name: "reviewLine2",
      type: "string",
      friendlyName: "Review Line 2",
    },
    {
      name: "name",
      type: "string",
    },
    {
      name: "title",
      type: "string",
      helperText: "e.g. 'CEO of Company Name'",
    },
  ],
});

Builder.registerComponent(SubNavbar, {
  name: "Secondary Navigation Bar",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fefd359d65e9947fcbc58d09bad752b43",
  inputs: [
    {
      name: "backgroundColor",
      type: "color",
      defaultValue: "#05182B",
    },
    {
      name: "textColor",
      type: "color",
      defaultValue: "white",
    },
    {
      name: "links",
      type: "list",
      subFields: [
        {
          name: "text",
          type: "string",
        },
        {
          name: "href",
          type: "string",
        },
      ],
    },
  ],
});

Builder.registerComponent(Sliver, {
  name: "Sliver / headline",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F907c3b9cae864def967ca199d0d30178",
  inputs: [
    {
      name: "href",
      type: "string",
      defaultValue: "/",
    },
    {
      name: "text",
      type: "string",
      defaultValue: "",
    },
  ],
});

Builder.registerComponent(FacilityComp, {
  name: "Facility Card",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F9dda7e2b38354ff388b158758951abdd",
  inputs: [
    {
      name: "img",
      type: "file",
      friendlyName: "Image",
    },
    {
      name: "title",
      type: "string",
    },
    {
      name: "description",
      type: "longText",
    },
    {
      name: "href",
      type: "string",
    },
  ],
});

Builder.registerComponent(ServiceCard, {
  name: "Expandable Service Card",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F66e7c8ee45c44d6397d931b5331e85d7",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "img",
      type: "file",
      friendlyName: "Image",
    },
    {
      name: "description",
      type: "string",
    },
    {
      name: "href",
      type: "string",
    },
  ],
});

Builder.registerComponent(Hero, {
  name: "Hero Section (main service pages)",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F769b0e1517bc4349885dcd07c2aeab7f",
  inputs: [
    {
      name: "videoUrl",
      type: "string",
    },
    {
      name: "placeholder",
      type: "file",
    },
    {
      name: "title",
      type: "string",
      friendlyName: "Title/H1",
    },
    {
      name: "subtitle",
      type: "string",
      friendlyName: "Subtitle/H2",
    },
    {
      name: "description",
      type: "longText",
    },
    {
      name: "tagline",
      type: "string",
    },
  ],
});

Builder.registerComponent(VideoFeature, {
  name: "Video Feature",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2Fccf105c5d7d24deda161804c3ff6596b",
  inputs: [
    { name: "videoUrl", type: "string" },
    { name: "titleLine1", type: "string", friendlyName: "Title Line 1" },
    { name: "titleLine2", type: "string", friendlyName: "Title Line 2" },
    {
      name: "descriptionLine1",
      type: "longText",
      friendlyName: "Description Paragraph 1",
    },
    {
      name: "descriptionLine2",
      type: "longText",
      friendlyName: "Description Paragraph 2",
    },
    {
      name: "link",
      type: "object",
      subFields: [
        { name: "href", type: "string" },
        { name: "text", type: "string" },
      ],
    },
  ],
});

Builder.registerComponent(CoreServices, {
  name: "Core Services highlight",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F7b14aa6c804f48b2b93db68709023e45",
  inputs: [
    {
      name: "services",
      type: "list",
      subFields: [
        { name: "title", type: "string", friendlyName: "Service" },
        {
          name: "href",
          type: "string",
          helperText: "e.g. /snow or https://....",
          friendlyName: "Slug or href",
        },
        { name: "icon", type: "file" },
      ],
    },
  ],
});

Builder.registerComponent(CoreValuesBlock, {
  name: "Core Values Block",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F754435ce8a0f4319bd8d2636dfd065e1%2F4b294d0f064543e9b38544a0058138e8",
  inputs: [
    {
      name: "img",
      friendlyName: "Image",
      type: "file",
    },
    {
      name: "text",
      friendlyName: "Text",
      type: "longText",
    },
    {
      name: "imgSide",
      friendlyName: "Image Side",
      type: "string",
      enum: ["left", "right"],
    },
  ],
});

export const wrapRootElement = ({ element }) => (
  <PageFirstSeen>
    <ScreenSizeContextWrapper>
      <ThemeContext>{element} </ThemeContext>
    </ScreenSizeContextWrapper>
  </PageFirstSeen>
);
