import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

function ContentDepthList({ title, list }) {
  const array = list?.map((b) => b.item) || [];
  return (
    <Box sx={{ width: "90vw", maxWidth: "1000px", m: "0 auto" }}>
      <Typography
        sx={{
          fontWeight: 500,
          lineHeight: "normal",
          width: "90vw",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
        variant="h6"
      >
        {title}
      </Typography>
      <ul>
        {array.map((item) => (
          <li>
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: "normal",
                width: "90vw",
                maxWidth: "1000px",
                margin: "0 auto",
              }}
              variant="h6"
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
}

export default ContentDepthList;
