import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const IconComp = ({ icon, text, title }) => {
  return (
    <Grid item xs={12} md={4}>
      {title && (
        <Typography
          variant="h3"
          sx={{ mb: 0, fontWeight: 600, fontFamily: "Inter" }}
        >
          {title}
        </Typography>
      )}
      <img src={icon} alt="" />
      <Typography
        sx={{
          color: "#86868B",
          textAlign: { xs: "center", md: "left" },
        }}
        variant="body1"
      >
        {text}
      </Typography>
    </Grid>
  );
};

function InformationContentThirds({ sections }) {
  const array = sections || [];
  return (
    <>
      <Grid container spacing={{ xs: 5, md: 12 }} px={{ xs: "5vw", md: 0 }}>
        {array.map((section, index) => {
          return (
            <IconComp
              key={index}
              icon={section.icon}
              text={section.text}
              title={section.title}
            />
          );
        })}
      </Grid>
    </>
  );
}

export default InformationContentThirds;
