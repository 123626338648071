import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import centeredStyle from "../styles/centeredStyle";

const Service = ({ service }) => {
  const { title, href, icon } = service;
  return (
    <Box sx={{ textAlign: "center", my: 2 }}>
      <img
        loading="lazy"
        src={icon}
        style={{ margin: "0 auto", height: "93px" }}
      />

      <br />
      <Typography
        variant="h6"
        sx={{ mb: 2, mt: 3, fontFamily: "Inter", fontWeight: 500 }}
      >
        {title}
      </Typography>
      <Typography
        component="a"
        variant="subtitle2"
        href={href}
        sx={{
          color: "#0066CC",
          fontFamily: "Inter",
          textAlign: "center",
          pl: 1,
          textDecoration: "none",
        }}
      >
        Learn More &gt;
      </Typography>
    </Box>
  );
};

function CoreServices({ services }) {
  const array = services || [];
  return (
    <Box
      sx={{
        width: "100vw",
        py: { xs: "35vh", sm: "15vh", md: "15vh", lg: "30vh", xl: "20vh" },
        position: "relative",
      }}
    >
      <Box sx={{ ...centeredStyle, textAlign: "center" }}>
        <Grid container sx={{ width: "90vw", maxWidth: "800px" }}>
          {array.map((service) => (
            <Grid item xs={6} sm={3} md={3} my="auto">
              <Service service={service} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default CoreServices;
