import React, { useEffect } from "react";

function PageFirstSeen({ children }) {
  useEffect(() => {
    if (window && !localStorage.getItem("firstSeen")) {
      console.log("location", window.location.pathname);
      localStorage.setItem("firstSeen", window.location.pathname);
    }
    if (window && !localStorage.getItem("gclid")) {
      const params = new URLSearchParams(window.location.search);
      console.log(params);
      const gclid = params.get("gclid");
      if (gclid) {
        console.log(gclid);
        localStorage.setItem("gclid", gclid?.value);
      }
    }
  }, [window]);

  return <>{children}</>;
}

export default PageFirstSeen;
