import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";

function LandscapingHero({ title, subtitle }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;

  return (
    <Box sx={{ width: "100vw", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 4,
          top: 0,
          left: 0,
          position: "absolute",
          py: 5,
        }}
      />
      <div
        className={`small-header ${screenSize}`}
        style={{ backgroundColor: "#86868B" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontFamily: "Inter",
            zIndex: 5,
            fontWeight: 700,
            width: "100vw",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "white",
            fontFamily: "Inter",
            zIndex: 5,
            fontWeight: 600,
            mt: { xs: 1 },
            width: "100vw",
            textAlign: "center",
            width: "100%",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

export default LandscapingHero;
