import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinkComp from "../components/linkComp/linkComp";

function InternalLinkingThirds({ sections }) {
  const array = sections || [];
  return (
    <Box
      sx={{
        py: { xs: "10vh", md: "10vh", xl: "9vh" },
        width: {
          xs: "90vw",
          sm: "60vw",
          md: "60vw",
          lg: "90vw",
          xl: "80vw",
        },
        ml: {
          xs: "5vw",
          sm: "20vw",
          md: "20vw",
          lg: "5vw",
          xl: "10vw",
        },
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 5, xl: 15 }}>
        {array.map((section) => (
          <LinkComp
            title={section.title}
            href={section.href}
            img={section.img}
            key={section.title}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default InternalLinkingThirds;
