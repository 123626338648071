import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useContext, useRef, useState } from "react";
import { ScreenSizeContext } from "../../contextWrappers/screenSizeContext";
import ContactModal from "../components/contactModal/contactModal";
import StyledButton from "../components/styledButton/styledButton";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WashingtonContact from "../forms/washingtonContact";
import centeredStyle from "../styles/centeredStyle";

function HeroContact({ video, placeholder, headerText }) {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { screenSize } = screenSizeContext;
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const handleScroll = (e) => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop,
    });
  };

  return (
    <>
      <ContactModal open={open} setOpen={setOpen} />
      {screenSize !== "small" && (
        <>
          {/* <img
            alt=""
            src={placeholder}
            style={{
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
              right: 0,
              top: 0,
              zIndex: -2,
              position: "relative",
            }}
          /> */}
          <video autoPlay loop muted preload="none" poster={placeholder}>
            <source src={video} />
          </video>
        </>
      )}

      {/* <div id={id} /> */}

      {(screenSize === "small" || screenSize === "medium") && (
        <Box
          sx={{
            color: "white",
            ...centeredStyle,
            width: "90vw",
            top: "calc(50% + 36px)",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            {headerText}
          </Typography>
          <Stack
            direction="row"
            spacing={5}
            sx={{ justifyContent: "center", mt: 7 }}
          >
            <StyledButton
              sx={{
                bgcolor: "white",
                fontFamily: "Inter",
                borderRadius: "30px",
                width: "140px",
                color: "black",
                fontWeight: 500,
                height: "37px",
                border: "2px solid white",
              }}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              get started <NavigateNext sx={{ fontSize: "16px" }} />
            </StyledButton>

            <StyledButton
              sx={{
                bgcolor: "black",
                fontFamily: "Inter",
                borderRadius: "30px",
                width: "140px",
                color: "white",
                fontWeight: 500,
                border: "2px solid white",
                height: "37px",
              }}
              variant="contained"
              onClick={handleScroll}
            >
              Learn More <ExpandMore sx={{ fontSize: "16px" }} />
            </StyledButton>
          </Stack>
        </Box>
      )}

      <Box ref={ref} sx={{ py: "10vw" }} />

      {(screenSize === "small" || screenSize === "medium") && (
        <Box sx={{ textAlign: "center" }}>
          <WashingtonContact service={headerText} />
        </Box>
      )}

      {(screenSize === "large" || screenSize === "xl") && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 5,
            textAlign: "center",
            width: "90vw",
            maxWidth: "1200px",
          }}
        >
          <Grid container>
            <Grid item xs={6} textAlign="center" my="auto">
              <Box
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  mb: 3,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    borderRight: "2px solid white",
                    pr: 1,
                  }}
                >
                  {headerText}
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    pl: 1,
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Inter",
                  pb: 2,
                  //   maxWidth: "90%",
                  m: "0 auto",
                  maxWidth: "430px",
                }}
                variant="body1"
              >
                Reach out to our dedicated team at Transblue to discover how we
                can elevate your commercial and multi-site operations by
                providing exceptional solutions tailored to your needs.
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Inter",
                  maxWidth: "90%",
                  m: "0 auto",
                  pb: 1,
                  variant: "body1",
                }}
              >
                We're here to help you succeed in your business ventures.
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="center" my="auto">
              <Box
                sx={{
                  maxWidth: "575px",
                  bgcolor: "white",
                  textAlign: "center",
                  borderRadius: "10px",
                  maxHeight: "90vh",
                  overflowY: "auto",
                  px: { xs: 2, sm: 3 },
                  pt: { xs: 2, sm: 3 },
                  pb: 3,
                }}
              >
                <WashingtonContact service={headerText} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box ref={ref} sx={{ py: "10vw" }} />
    </>
  );
}

export default HeroContact;
