import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { NavigateNext } from "@mui/icons-material";

const LeftComp = ({ name, title, body, image, link, alt }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        md={4}
        textAlign={{ xs: "center", md: "left" }}
        display={{ xs: "none", md: "block" }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mt: 1 }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#86868B", mb: 2 }}>{title}</Typography>
        <Typography
          sx={{
            color: "#1d1d1f",
            fontWeight: 500,
            pr: { xs: 0, md: 4, xl: 8 },
            mb: link ? 3 : 0,
          }}
        >
          {body}
        </Typography>
        {link && (
          <a
            href={link}
            target="blank"
            rel="noreferrer"
            style={{
              color: "#007AFF",
              fontFamily: "Inter",
              fontWeight: 500,
              textDecoration: "none",
            }}
          >
            Connect with {name.split(" ")[0]}{" "}
            <NavigateNext sx={{ fontSize: "18px" }} />
          </a>
        )}
      </Grid>
      <Grid item sm={3} display={{ sm: "block", md: "none" }} />
      <Grid item xs={12} sm={6} md={4}>
        <img
          src={image}
          alt={alt}
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        textAlign={{ xs: "center", md: "left" }}
        display={{ md: "none" }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mt: 1 }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#86868B", mb: 2 }}>{title}</Typography>
        <Typography
          sx={{
            color: "#1d1d1f",
            fontWeight: 500,
            pr: { xs: 0, md: 4, xl: 8 },
            mb: link ? 3 : 0,
          }}
        >
          {body}
        </Typography>
        {link && (
          <a
            href={link}
            target="blank"
            rel="noreferrer"
            style={{
              color: "#007AFF",
              fontFamily: "Inter",
              fontWeight: 500,
              textDecoration: "none",
            }}
          >
            Connect with {name.split(" ")[0]}{" "}
            <NavigateNext sx={{ fontSize: "18px" }} />
          </a>
        )}
      </Grid>
      <Grid item xs={12} md={4}></Grid>
    </>
  );
};

const RightComp = ({ name, title, body, image, link, alt }) => (
  <>
    <Grid item xs={12} md={4} />
    <Grid item sm={3} display={{ sm: "block", md: "none" }} />
    <Grid item xs={12} sm={6} md={4}>
      <img src={image} alt={alt} style={{ width: "90%", marginLeft: "5%" }} />
    </Grid>
    <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "right" }}>
      <Typography variant="h4" sx={{ fontWeight: 600, mt: 1 }}>
        {name}
      </Typography>
      <Typography variant="h6" sx={{ color: "#86868B", mb: 2 }}>
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#1d1d1f",
          fontWeight: 500,
          pl: { xs: 0, md: 4, xl: 6 },
          mb: link ? 3 : 0,
        }}
        variant="body1"
      >
        {body}
      </Typography>
      {link && (
        <a
          href={link}
          target="blank"
          rel="noreferrer"
          style={{
            color: "#007AFF",
            fontFamily: "Inter",
            fontWeight: 500,
            textDecoration: "none",
            fontSize: `calc(16px + 0.390625vw)`,
          }}
        >
          Connect with {name.split(" ")[0]}{" "}
          <NavigateNext sx={{ fontSize: "18px" }} />
        </a>
      )}
    </Grid>
  </>
);

function LeadershipTeamBlock({
  image,
  name,
  title,
  link,
  body,
  displayLink,
  textSide,
}) {
  return (
    <Grid container spacing={{ xs: 3, md: 2 }}>
      {textSide === "left" ? (
        <LeftComp
          image={image}
          name={name}
          title={title}
          link={displayLink ? link : null}
          body={body}
          alt={name}
        />
      ) : (
        <RightComp
          image={image}
          name={name}
          title={title}
          link={displayLink ? link : null}
          body={body}
          alt={name}
        />
      )}
    </Grid>
  );
}

export default LeadershipTeamBlock;
