import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function InformationContentBlock({ sectionTitle, link, sectionBody }) {
  return (
    <>
      <Box
        sx={{
          py: { xs: "15vh", md: "20vh", xl: "15vh" },
          width: "90vw",
          maxWidth: "800px",
          m: "0 auto",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {sectionTitle}
        </Typography>

        {sectionBody && (
          <Typography
            sx={{
              color: "#86868B",
              width: "100%",
              mt: { xs: 2, md: 5 },
              textAlign: "center",
            }}
            variant="body1"
          >
            {sectionBody}
          </Typography>
        )}
        {link && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Typography
              component="a"
              sx={{
                color: "#06C",
                textDecoration: "none",
                letterSpacing: "0.17px",
                textAlign: "center",
              }}
              href={link}
              variant="body2"
            >
              Learn more
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default InformationContentBlock;
